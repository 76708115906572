import * as React from 'react';
import { FC } from 'react';
import { CredentialType } from '../../state/slices/credentialtype';
import { SearchCredentialTypeView } from '../organisms/SearchCredentialTypeView';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../state';
import { issueCredentialType } from '../../state/slices/credentialtype/CredentialTypeApi';
import { useKeycloak } from '@react-keycloak/web';


export const CredentialTypeList: FC = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const {keycloak, initialized} = useKeycloak();

    function onRequestCredentialClicked(credentialType: CredentialType) {
        dispatch(issueCredentialType({jwtToken: keycloak.token!, credentialTypeId: credentialType.id!}))
            .then((data) => {
                    if (data.type.includes('fulfilled')) {
                        window.location.replace(data.payload.issueUrl);
                    }
                }
            );
    }

    return (
        <SearchCredentialTypeView onRequestCredentialClicked={onRequestCredentialClicked}
                                  searchTitle={t('screens.credentialTypeList.searchTitle')}
                                  userPreferencesKey="credentialTypeList"/>
    );
}
