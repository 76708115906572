import React from 'react';
import { IconProps } from './IconProps';


export const CheckboxUnchecked: React.FC<IconProps> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M10.75 2.5H9.25C7.82924 2.5 6.83885 2.50097 6.0678 2.56397C5.31134 2.62577 4.87673 2.74099 4.54754 2.90873C3.84193 3.26825 3.26825 3.84193 2.90873 4.54754C2.74099 4.87673 2.62577 5.31134 2.56397 6.0678C2.50097 6.83885 2.5 7.82924 2.5 9.25V10.75C2.5 12.1708 2.50097 13.1611 2.56397 13.9322C2.62577 14.6887 2.74099 15.1233 2.90873 15.4525C3.26825 16.1581 3.84193 16.7317 4.54754 17.0913C4.87673 17.259 5.31134 17.3742 6.0678 17.436C6.83885 17.499 7.82924 17.5 9.25 17.5H10.75C12.1708 17.5 13.1611 17.499 13.9322 17.436C14.6887 17.3742 15.1233 17.259 15.4525 17.0913C16.1581 16.7317 16.7317 16.1581 17.0913 15.4525C17.259 15.1233 17.3742 14.6887 17.436 13.9322C17.499 13.1611 17.5 12.1708 17.5 10.75V9.25C17.5 7.82924 17.499 6.83885 17.436 6.0678C17.3742 5.31134 17.259 4.87673 17.0913 4.54754C16.7317 3.84193 16.1581 3.26825 15.4525 2.90873C15.1233 2.74099 14.6887 2.62577 13.9322 2.56397C13.1611 2.50097 12.1708 2.5 10.75 2.5ZM1.79497 3.98005C1.25 5.04961 1.25 6.44974 1.25 9.25V10.75C1.25 13.5503 1.25 14.9504 1.79497 16.02C2.27433 16.9608 3.03924 17.7257 3.98005 18.205C5.04961 18.75 6.44974 18.75 9.25 18.75H10.75C13.5503 18.75 14.9504 18.75 16.02 18.205C16.9608 17.7257 17.7257 16.9608 18.205 16.02C18.75 14.9504 18.75 13.5503 18.75 10.75V9.25C18.75 6.44974 18.75 5.04961 18.205 3.98005C17.7257 3.03924 16.9608 2.27433 16.02 1.79497C14.9504 1.25 13.5503 1.25 10.75 1.25H9.25C6.44974 1.25 5.04961 1.25 3.98005 1.79497C3.03924 2.27433 2.27433 3.03924 1.79497 3.98005Z"
                  fill="#1C1C1C"/>
        </svg>
    )
};
