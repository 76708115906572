import React from 'react';
import { IconProps } from './IconProps';


export const CheckboxChecked: React.FC<IconProps> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M1.25 9.25C1.25 6.44974 1.25 5.04961 1.79497 3.98005C2.27433 3.03924 3.03924 2.27433 3.98005 1.79497C5.04961 1.25 6.44974 1.25 9.25 1.25H10.75C13.5503 1.25 14.9504 1.25 16.02 1.79497C16.9608 2.27433 17.7257 3.03924 18.205 3.98005C18.75 5.04961 18.75 6.44974 18.75 9.25V10.75C18.75 13.5503 18.75 14.9504 18.205 16.02C17.7257 16.9608 16.9608 17.7257 16.02 18.205C14.9504 18.75 13.5503 18.75 10.75 18.75H9.25C6.44974 18.75 5.04961 18.75 3.98005 18.205C3.03924 17.7257 2.27433 16.9608 1.79497 16.02C1.25 14.9504 1.25 13.5503 1.25 10.75V9.25Z" fill="#1C1C1C"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M13.7609 7.74863C14.1414 8.09982 14.1651 8.69294 13.8139 9.07339L10.2997 12.8804C9.7958 13.4263 8.92975 13.4148 8.44051 12.8557L6.48197 10.6174C6.14102 10.2277 6.18051 9.63542 6.57016 9.29446C6.95982 8.95351 7.5521 8.993 7.89305 9.38266L9.39366 11.0976L12.4361 7.80162C12.7873 7.42116 13.3804 7.39744 13.7609 7.74863Z"
                  fill="white"/>
        </svg>
    )
};
