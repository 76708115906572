import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading } from '../global';

import { bearerAuth } from '../auth';
import axios from 'axios';
import { CredentialStatusList } from './CredentialStatusSlice';


export const getCredentialStatusConfigurations = createAsyncThunk(
    'credentialStatus/getCredentialStatusConfigurations', ({jwtToken}: { jwtToken: string | undefined }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };

        var url = '/credentialStatusList';

        return axios.get(url, config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const getCredentialStatusConfiguration = createAsyncThunk(
    'credentialStatus/getCredentialStatusConfiguration', ({jwtToken, credentialStatusId}: { jwtToken: string | undefined, credentialStatusId: string }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };


        var url = '/credentialStatusList/' + credentialStatusId;
        return axios.get(url, config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const saveCredentialStatusList = createAsyncThunk(
    'credentialStatus/saveCredentialStatusList', ({jwtToken, credentialStatusList}: { jwtToken: string | undefined, credentialStatusList: CredentialStatusList }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };

        var url = '/credentialStatusList';
        if (credentialStatusList.id !== undefined) {
            url = url + '/' + credentialStatusList.id;
        }
        return axios.post(url, credentialStatusList, config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

