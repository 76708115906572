import React, { PropsWithChildren } from 'react';
import { Did } from '../../state/slices/did';
import { Dropdown } from 'primereact/dropdown';
import { CredentialStatusList } from '../../state/slices/credentialstatus';


export interface CredentialStatusListSelectListProps {
    credentialStatusLists: CredentialStatusList[];
    selectedStatusList: CredentialStatusList | undefined;
    onSelect: (statusList: CredentialStatusList) => void;
    className?: string | undefined;
}

export const CredentialStatusListSelectList: React.FC<CredentialStatusListSelectListProps & PropsWithChildren> = (props) => {
    function onSelectItem(statusList: CredentialStatusList) {
        props.onSelect(statusList);
    }

    return (
        <Dropdown value={props.selectedStatusList} onChange={(e) => onSelectItem(e.value)}
                  options={props.credentialStatusLists} optionLabel="name"
                  placeholder="Select a credential status list"
                  className="w-full md:w-14rem"
                  checkmark={true}
                  highlightOnSelect={false} />

    );
};

