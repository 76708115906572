import React from 'react';
import { IconProps } from './IconProps';


export const BookOpen: React.FC<IconProps> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 20 20" fill="none" className={props.className}>
            <path
                d="M18.125 5V15C18.125 15.1658 18.0592 15.3247 17.9419 15.4419C17.8247 15.5592 17.6658 15.625 17.5 15.625H12.5C11.837 15.625 11.2011 15.8884 10.7322 16.3572C10.2634 16.8261 10 17.462 10 18.125C10 17.462 9.73661 16.8261 9.26777 16.3572C8.79893 15.8884 8.16304 15.625 7.5 15.625H2.5C2.33424 15.625 2.17527 15.5592 2.05806 15.4419C1.94085 15.3247 1.875 15.1658 1.875 15V5C1.875 4.83424 1.94085 4.67527 2.05806 4.55806C2.17527 4.44085 2.33424 4.375 2.5 4.375H7.5C8.16304 4.375 8.79893 4.63839 9.26777 5.10723C9.73661 5.57607 10 6.21196 10 6.875C10 6.21196 10.2634 5.57607 10.7322 5.10723C11.2011 4.63839 11.837 4.375 12.5 4.375H17.5C17.6658 4.375 17.8247 4.44085 17.9419 4.55806C18.0592 4.67527 18.125 4.83424 18.125 5Z"
                fill="#1C1C1C" fillOpacity="0.1"/>
            <path d="M17.5 3.75H12.5C12.0149 3.75 11.5364 3.86295 11.1025 4.07991C10.6685 4.29688 10.2911 4.61189 10 5C9.70892 4.61189 9.33147 4.29688 8.89754 4.07991C8.46362 3.86295 7.98514 3.75 7.5 3.75H2.5C2.16848 3.75 1.85054 3.8817 1.61612 4.11612C1.3817 4.35054 1.25 4.66848 1.25 5V15C1.25 15.3315 1.3817 15.6495 1.61612 15.8839C1.85054 16.1183 2.16848 16.25 2.5 16.25H7.5C7.99728 16.25 8.47419 16.4475 8.82583 16.7992C9.17746 17.1508 9.375 17.6277 9.375 18.125C9.375 18.2908 9.44085 18.4497 9.55806 18.5669C9.67527 18.6842 9.83424 18.75 10 18.75C10.1658 18.75 10.3247 18.6842 10.4419 18.5669C10.5592 18.4497 10.625 18.2908 10.625 18.125C10.625 17.6277 10.8225 17.1508 11.1742 16.7992C11.5258 16.4475 12.0027 16.25 12.5 16.25H17.5C17.8315 16.25 18.1495 16.1183 18.3839 15.8839C18.6183 15.6495 18.75 15.3315 18.75 15V5C18.75 4.66848 18.6183 4.35054 18.3839 4.11612C18.1495 3.8817 17.8315 3.75 17.5 3.75ZM7.5 15H2.5V5H7.5C7.99728 5 8.47419 5.19754 8.82583 5.54917C9.17746 5.90081 9.375 6.37772 9.375 6.875V15.625C8.83458 15.2183 8.17633 14.9989 7.5 15ZM17.5 15H12.5C11.8237 14.9989 11.1654 15.2183 10.625 15.625V6.875C10.625 6.37772 10.8225 5.90081 11.1742 5.54917C11.5258 5.19754 12.0027 5 12.5 5H17.5V15Z" fill="#1C1C1C"/>
        </svg>
    )
};
