import { createSlice } from '@reduxjs/toolkit';
import { addGenericStateListBuilders, addGenericStateSingleBuilders } from '../slice';
import { getCredentialStatusConfiguration, getCredentialStatusConfigurations, saveCredentialStatusList } from './CredentialStatusApi';
import { defaultGenericState, GenericState } from '../model';

export interface CredentialStatusList {
    id?: number;
    type?: string;
    name?: string;
    purpose?: string;
    externalUrl?: string;
    createdAt?: Date;
    createdBy?: String;
    lastModifiedAt?: Date;
    lastModifiedBy?: String;
}

export interface CredentialStatusState extends GenericState<CredentialStatusList> {
}

export const credentialStatusSlice = createSlice({
    name: 'credentialStatus',
    initialState: defaultGenericState,
    reducers: {},
    extraReducers: builder => {
        addGenericStateListBuilders(builder, getCredentialStatusConfigurations);
        addGenericStateSingleBuilders(builder, getCredentialStatusConfiguration);
        addGenericStateSingleBuilders(builder, saveCredentialStatusList);
    }
});

